
body {
    font-family: Arial, sans-serif;
    background-color: #000000;
    color: #121149;
    background-image: url('/./public/Island.jpg');
    background-size: cover; /* Забезпечте, що зображення заповнює фон повністю */
    background-repeat: no-repeat; /* Забороніть повторення фону */
    background-attachment: fixed; /* Фонове зображення закріплюється */
    flex-direction: column;
    display: flex;
    min-height: 100vh;
    width: auto;
    margin: 0;
    padding: 0px;
    position: relative;
    justify-content: center;/* Вирівнює контент по центру внизу */
}

header {
  background-color: rgba(255, 20, 147, 0.7); /* Колір фону хедера з прозорістю */
    padding: 30px;
    text-align: center;
    position: fixed;
    cursor: pointer;
    z-index: 2; /* Збільшуємо z-index хедера, щоб він був поверх відео */
    align-items: center;
}

#fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  height: 24px; /* Задайте висоту хедера за вашими потребами */
  display: flex;
  justify-content: center; /* Вирівнюємо текст по горизонталі по центру */
  align-items: center; /* Вирівнюємо текст по вертикалі по центру */
}


main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px; /* Додайте бажаний зовнішній відступ для основного контенту */
  margin-bottom: 70px ;
}
 


.main-container {
   display: flex;
   flex-direction: column;
   align-items: center;
  
}

.container-example-photo {
  display: flex;
   flex-direction: column;
   align-items: center;
}

.container-item  {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0;

}

.container-item img {
  max-width: 80%;
  max-height: 100%;
}

.button-text {
  font-size: 56px; /* Змінюємо розмір тексту кнопки */
  color: #00FF00;
  padding: auto; /* Внутрішній відступ для тексту */
  
}
 
.main-container button {
  background-color: rgba(255, 20, 147, 0.7);
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 15px; /* Додайте зовнішній відступ між кнопками */
  width: auto; /* Автоматична ширина на основі вмісту тексту */
  height: auto; /* Автоматична висота на основі вмісту тексту */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  border-radius: 15px; /* Зробіть кути кнопок трохи заокругленими */
}




footer {
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 20, 147, 0.7);
  font-size: 30px;
  width: 100%;
  height: auto; /* Додайте висоту в пікселях або інших одиницях вимірювання, яка підходить вашим потребам */
    }


#fixed-footer {
  position: fixed;
  left: 0;
  bottom: 0; /* Розміщуємо футер внизу */
}

.ul-footer {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.li-footer {
  margin: 8px;
  display: flex;
  align-items: center; /* Вирівнюємо текст по вертикалі по центру */
}

.a-text-footer {
  color: #00FF00;
  text-decoration: none;
  display: flex;
  align-items: center; /* Вирівнюємо текст по вертикалі по центру */
}

/* Стилі для зміни курсору на "pointer" при наведенні на Distance-Video */
.Distance-Video:hover {
  cursor: pointer;
}


.video-example{
  border: 5px solid #000080;
  position: relative;
  width: auto;

  
}

/* Стилі для нормальних посилань */
a {

  text-decoration: none; /* Відсутність підкреслення */
}

/* Стилі для посилань, які стали відвіданими */
a:visited {
  color: #00FF00; /* Колір тексту (може бути таким же, як і для нормальних посилань) */
}

.contacts {
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  
}


.franchise {
  display: flex;
  color: #000080;
  font-size: 21px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start; /* Вирівнюємо текст по лівому краю */
  height: auto;
  background-color: rgba(255, 200, 200, 0.8);
  padding: 15px; /* Встановлюємо внутрішній відступ */
  word-wrap: break-word; /* Автоматичне перенесення слів */
  /* Або overflow-wrap: break-word; - це аналогічний стиль для автоматичного перенесення слів */
}

.instruction {
  display: flex;
  font-size: 21px;
  color: #000080;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start; /* Вирівнюємо текст по лівому краю */
  height: auto;
  background-color: rgba(255, 200, 200, 0.8);
  padding: 15px; /* Встановлюємо внутрішній відступ */
  word-wrap: break-word;
}

.gallery {
  display: flex;
  color: #000080;
  justify-content: center;
  align-items: center;
}

.gallery iframe {
  max-width: 80%; /* Змініть значення за необхідністю */
}

.button-payment {
  
  color: #00FF00;
  padding: auto; /* Внутрішній відступ для тексту */
}

.button-connect {
  
  color: #00FF00;
  padding: auto; /* Внутрішній відступ для тексту */
}

.button-back {
  background-color: #0ba70b;
  color: #fff;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center; /* Горизонтальне центрування тексту */
  padding: 10px;
  margin: 15px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px; /* Вертикальне центрування тексту */
  
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Встановити фіксований розмір стовпців */
}

th, td {
  border: 1px solid #121149; /* Встановлення рамки для всіх комірок */
  padding: 4px;
  text-align: center;
}

th {
  background-color: #0ba70b;
}

.table-container {
  overflow-x: auto;
  
}

/* Стилі для розміщення іконок */

.social-icons li {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px; /* Задайте бажаний відступ між елементами */
}
.social-icons li:nth-child(1) i {
  /* Стилі для іконки YouTube */
  color: red; /* Змініть колір на потрібний */
  font-size: 24px; /* Змініть розмір шрифту на потрібний */
 
  
}

.social-icons li:nth-child(2) i {
  /* Стилі для іконки Instagram */
  color: blue; /* Змініть колір на потрібний */
  font-size: 24px; /* Змініть розмір шрифту на потрібний */

}

.social-icons li:nth-child(3) i {
  /* Стилі для іконки Facebook */
  color: #3b5998; /* Змініть колір на потрібний */
  font-size: 24px; /* Змініть розмір шрифту на потрібний */
 
}

.cost {
  color: #000080;
}

.button-instruction {
  color: #000080; 
  font-size: 24px;
}

.ul-profile {
  list-style: none;
  font-size: 21px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; /* Розташування в стовпчик */
  align-items: center; /* Центруємо по горизонталі */
  text-align: center; /* Центруємо текст */
  word-wrap: break-word; /* Додавання переносу слів */
  width: 100%; /* Ширина 100% для рівномірного переносу */
}


.li-profile {
  margin: 8px;
  color: #000080;
  display: flex;
  word-wrap: break-word; /* Додавання переносу слів */
  align-items: center; /* Вирівнюємо текст по вертикалі по центру */
}

.profile-fon, .informationabout-fon {
  background-color: rgba(255, 200, 200, 0.8);
}

.informationabout-fon {
  background-color: rgba(255, 200, 200, 0.8);
}

.span-li-profile {
  font-family: Arial, sans-serif; /* Задаємо шрифт */
  color: #000080;
  font-size: 34px;
}

.photo-description {
  font-family: Arial, sans-serif;
  font-size: 28px;
  color: #000080 ;
  text-align: center;
  margin-top: 10px; /* можна налаштувати відступ від фото */
}

.ul-freelocations {
  list-style: none;
  color: #000080 ;
  font-size: 21px;
  padding: 2;
  margin: 0;
  display: flex;
  flex-direction: column; /* Розташування в стовпчик */
  align-items: center; /* Центруємо по горизонталі */
  text-align: center; /* Центруємо текст */
  word-wrap: break-word; /* Додавання переносу слів */
  width: 100%; /* Ширина 100% для рівномірного переносу */
  background-color: rgba(255, 200, 200, 0.8);
}

.free-text {
  color: #000080 ;
  font-size: 25px;
}



.registr-form {
  display: flex;
  color: #000080;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 200, 200, 0.8);
}

.registr-form form {
  display: flex;
  color: #000080;
  flex-direction: column;
  align-items: center;
  width: 90%; /* Ширина форми */
  max-width: 400px; /* Максимальна ширина форми */
  margin: 0 auto; /* Вирівнювання по центру */
}

.registr-form label {
  margin-bottom: 10px; /* Відступ між полями */
}

.registr-form input,
.registr-form select,
.registr-form textarea {
  width: 100%; /* Ширина вводу */
  padding: 8px; /* Внутрішні відступи */
  margin-bottom: 15px; /* Відступ між елементами форми */
  box-sizing: border-box; /* Загальний розмір елемента, включаючи рамку та поля */
}

